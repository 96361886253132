<template>
  <div class="">
    <SimplePage
      section="contact"
      :pre_title="$t('Contáctanos')"
      :title="$t('¿Cómo podemos <br> ayudarte?')"
      :button_text="$t('Escribenos un mensaje')"
      @buttonClicked="toggleFormModal()"
    ></SimplePage>
    <Modal :is_open="form_modal_open"> 
      <app-section :title="$t('Contáctanos')" :bootom_blue_line="true"></app-section>
      <div class="row justify-content-center mt-5">
        <div class="col-9">
          <Form 
              form_type="contact"
              :form_success_title="$t('Gracias por contactarnos')"
              :watch_change_prop="form_modal_open"
          ></Form>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import SimplePage from "@/components/modules/SimplePage";
import Modal from "@/components/modules/Modal";
import Form from "@/components/forms/Form";
import AppSection from "@/components/AppSection";
export default {
  name: "ContactUs",
  components: {
    SimplePage,
    Modal,
    Form,
    AppSection
  },
  data: function(){
    return {form_modal_open: false}
  },
  methods: {
    toggleFormModal(){
      this.form_modal_open = !this.form_modal_open;
    }
  }
}
</script>

<style scoped lang="scss">
</style>